import { createRouter, createWebHistory } from "vue-router";
import Index from "./components/IndexPage/IndexPage.vue";
import MovieDetails from "./components/MovieDetails/MovieDetails.vue";
import Seat from "./components/Seat/SeatPage.vue";
import MTicket from "./components/Mticket/MTicket.vue";
import UserProfile from "./components/UserProfile/UserProfile.vue";
import Contact from "./components/ContactUs/ContactUs.vue";
import clearData from "./components/UI/ClearSocial.vue";
import sample from "./components/sample/ProgWrap.vue";
import NotFound from "./components/UI/NotFound.vue";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy.vue";
import TermOfService from "./components/Footer/TermOfService.vue";
import RefundPolicy from "./components/Footer/RefundPolicy.vue";
import ticketFailed from "./components/UI/TicketFail.vue";

import Test from "./components/try/TryPage.vue";







const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name:'home',
            components:{default:Index},

        },
        {
            name: "index",
            path: "/index",
            components: { default: Index },
        },
        {
            name: "MovieDetails",
            path: "/movie/:slug",
            components: { default: MovieDetails },
        },
        {
            name: "seat",
            path: "/book-ticket/:showid",
            components: { default: Seat },
        },
        {
            name: "ticket",
            path: "/mticket",
            components: { default: MTicket },
        },
        {
            name: "my-ticket",
            path: "/my-mticket/:id",
            components: { default: MTicket },
        },
        {
            name: "contact",
            path: "/contact",
            components: { default: Contact },
        },
        
        {
            name: "profile",
            path: "/profile",
            components: { default: UserProfile },
        },
        {
            name: "clear",
            path: "/clear",
            components: { default: clearData },
        },
        {
            path: '/sample',
            name:'sample',
            components:{default:sample},

        },
        {
            path: '/termofservice',
            name:'termofservice',
            components:{default:TermOfService},

        },
        {
            path: '/refundpolicy',
            name:'refundpolicy',
            components:{default:RefundPolicy},

        },
        {
            path: '/privacypolicy',
            name:'privacypolicy',
            components:{default:PrivacyPolicy},

        },
        {   
            name: "notfound",
            path: '/:notFound(.*)',
            components: { default: NotFound },
        },
        {
            path: '/ticket-failed',
            name:'ticketFailed',
            components:{default:ticketFailed},

        },



        {
            path: '/test',
            name:'Sample',
            components:{default:Test},

        },
       
    ],


    linkActiveClass: 'active',
});
export default router;