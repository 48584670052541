<template>
  <h1>Tesssghh</h1>
  <swiper
  :effect="'coverflow'"
  :loop="true" 
  :grabCursor="true"
  :auto="true"
  :centeredSlides="true"
  :speed="600"
  :coverflowEffect="{
     rotate: 0,
    stretch: 0,
    depth: 100,
    modifier: 2,
    slideShadows: true
    }"
    :slidesPerView= "3"
    :autoplay="{
    delay: 3000
  }"
    :modules="modules"
    :slides-per-view="2"
    :space-between="0"
    :pagination="{ clickable: true }"
    :scrollbar="{ draggable: true }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
  <swiper-slide><img src="https://swiperjs.com/demos/images/nature-1.jpg" /></swiper-slide>
    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-2.jpg" /></swiper-slide>
    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-3.jpg" /></swiper-slide>
    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-4.jpg" /></swiper-slide>
    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-5.jpg" /></swiper-slide>
    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-6.jpg" /></swiper-slide>
    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-7.jpg" /></swiper-slide>
    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-8.jpg" /></swiper-slide>
    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-9.jpg" /></swiper-slide>

  </swiper>
</template>
<script>
  // import Swiper core and required modules
  import {  A11y,EffectCoverflow,Autoplay } from 'swiper';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';
  import 'swiper/css/effect-coverflow';
  import 'swiper/css/autoplay';

  // Import Swiper styles
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [  A11y,EffectCoverflow,Autoplay],
      };
    },
  };
</script>
