<template>
  <section style="background-image: url(assets/img/bg2.png); background-size: cover; background-repeat: no-repeat;" class=" nopad combg explore tabs-fade" data-scroll-index="2" >
    <div class="container-fluid" style="padding: 50px 40px ">
      <div class="row">
       
        <div class="col-6 disno" >
          <div class="circle-layer" style="background-image: url(assets/img/bg.png); opacity: 0.2;" v-if="Results"></div>
          <div class="banner-text"  style="padding-top: 40px;">
            <div style="width: 100%;" >
              <div class=""  style="">
                <transition mode="out-in" name="slide-in-left">
                <h1 style="font-size: 35px;font-weight: 800;"  class="">{{ currentMovie.title}}</h1>
              </transition>
              </div>
              <div class=""  style=""><div class="trending-list" >
                <div class="d-flex align-items-center mt-2 mb-2 mt-md-3" >
                  <span class="badge badge-secondary p-2" >{{ currentMovie.cert}}</span>
                  <span class="ml-3" >{{ currentMovie.dur}}</span></div><!---->
                  <transition name="fadeOne" mode="out-in">
                  <div class="hiddenxs  mb-2"  style="font-size: 13px;"  v-if="currentMovie.staring"> <span class="banfon"> Starring : </span> <span >
                    {{ currentMovie.staring}}</span></div>
                  </transition>
                    <div class="hiddenxs mb-2"  style="font-size: 13px;" v-if="currentMovie.dir"><span class="banfon">   Directed by : </span> <span >
                      {{ currentMovie.dir }}</span></div><div style="font-size: 13px;" class="hiddenxs  mb-2" v-if="currentMovie.lang"> <span class="banfon">  Language </span>
                         <span >{{getLang(Languages,currentMovie.lang)}}</span></div></div></div><div >
                          <router-link  class=" bor15 xsfont10 btn btn-hover iq-button font10" v-if="currentMovie.slug" :to="'movie/'+currentMovie.slug"><svg class="svg-inline--fa fa-ticket fa-rotate-45" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ticket" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" ><path class="" fill="currentColor" d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"></path></svg> Book Tickets </router-link>
                          
                    </div></div></div>
        </div>
        <div class="col-12 col-md-6 col-lg-6" >
          <swiper v-if="Results"
  :effect="'coverflow'"
  :grabCursor="true"
  :auto="true"
  :centeredSlides="true"
  :speed="600"
  :initialSlide="1"
  :coverflowEffect="{
    rotate: 0,
    stretch: 0,
    depth: 100,
    modifier: 2,
    slideShadows: true
    }"
    :autoplay="{
    delay: 3000
  }"
    :modules="modules"
    :slides-per-view="2"
    :space-between="0"
    :pagination="{ clickable: true }"
    :scrollbar="{ draggable: true }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
  <swiper-slide v-for="item in Results"
                  :key="item.title"> <div class="poster ">  <aside><div>
                    <router-link  style="border: solid 1px aliceblue;"  class="btn btn-blc" v-if="item.slug" :to="'movie/'+item.slug">
 Book Now</router-link>
</div>
</aside> <img  v-if="item.poster"
                          :src="uploads + item.poster"
                          :alt="item.title" />
                        </div>
                        
                        </swiper-slide>

  </swiper>
        </div>
      </div>
    </div>
  </section>
</template>
<style type="text/css">

.fadeOne-enter-active, .fadeOne-leave-active,.fadeThree-enter-active, .fadeThree-leave-active {
  transition: all 1s ease-out;
}
.fadeOne-enter, .fadeOne-leave-to {
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  transform: translate(0%, 100%);
}
.fadeThree-enter, .fadeThree-leave-to {
   clip-path: polygon(0% 100%, 100% 100%, 0% 100%, 0% 100%);
  transform: translate(100%, 0%);
}

.fadeTwo-enter-active, .fadeTwo-leave-active {
  transition: all .5s ease-out;
}
.fadeTwo-enter, .fadeTwo-leave-to {
  clip-path: polygon(0% 100%, 100% 100%, 0% 100%, 0% 100%);
  transform: translate(0%, -100%);
}
  .slide-in-left-enter-active {
    -webkit-animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-left-leave-active{
  -webkit-animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
          animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
}


@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
</style>
<script>


import {  A11y,EffectCoverflow,Autoplay } from 'swiper';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';
  import 'swiper/css/effect-coverflow';
  import 'swiper/css/autoplay';


import { URL,UPLOADS } from "../../Conf.js";
import axios from "axios";

export default {

components: {
    Swiper,
      SwiperSlide,
  },
  data() {
    return {
      Results: null,
      curindex:0,
      currentMovie:{},
      url: URL + "../uploads/",
      uploads:UPLOADS,
      vbox: null,
      modules: [  A11y,EffectCoverflow,Autoplay],
      Languages:{"1":"Malayalam",'2':"Tamil",'3':"Hindi",'4':"English",'5':"Telugu",'6':"Kannada"},
      
    };
  },
  methods: {
     async getData() {
      axios.get(URL + "movies/1").then((response) => {
        this.Results = response.data.result;
        this.currentMovie=this.Results[0];
      }).then(()=>{
        
      })
    },
     onSwiper(){
      },
      onSlideChange (swipe){
        this.currentMovie={};
        this.curindex=swipe.activeIndex;
        this.currentMovie=this.Results[(swipe.activeIndex)];
      }


  },

  async created() {
    await this.getData();
  },
  async mounted() {
  },
  computed:{
    getLang : ()=>{
            return (langs,key) => {
            return langs[key]
        }

      }
  }

};
</script>


