<template>
 <SiteProgress></SiteProgress>
    <SiteHeader @locUpdated="locUpdated" ref="headCmp"></SiteHeader>
<IndexBanner ref="bannerComp"></IndexBanner>
  <IndexMovies  ref="hederComp" ></IndexMovies>

<IndexComingSoon ></IndexComingSoon>

<SiteFooter></SiteFooter>



</template>
<script>
import IndexBanner from './IndexBanner.vue';
import IndexMovies from './IndexMovies.vue';
import IndexComingSoon from './IndexComingSoon.vue';
import SiteFooter from '../UI/SiteFooter.vue';
import SiteHeader from '../UI/SiteHeader.vue';
import SiteProgress from '../UI/SiteProgress.vue';


export default {
    data:()=>({
        locChanged:false,
    }),
    components:{
        IndexBanner,
        IndexMovies,
        IndexComingSoon,
        SiteFooter,
        SiteHeader,
        SiteProgress
    },
    methods:{
        locUpdated(arg){
            this.locChanged=true;
            this.$refs.hederComp.getMovies();
            this.$refs.bannerComp.locUpdated(arg);
        },

    },
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Default Title',
      // all titles will be injected into this template
      titleTemplate: '%s | My Awesome Webapp'
    }
 


}
</script>


