<template>
<SiteProgress></SiteProgress>
<SiteHeader></SiteHeader>
<div class="container small" style="background-color: #rgb(28, 19, 31);" v-if="!LoadingDetail">
                <div class="metaportal_fn_mint_top row pad50" >
                  <div class="nopad" v-if="!showtimeLoader">
                    <DatePaginator :totalPages="lastPage" :currentPage="currentPage" @pagechanged="onPageChange" :dates="datePages"></DatePaginator>
                  </div>
                    <div class="row">
                    <div class="mint_left col-4 col-lg-3 col-md-3" >
                        <div class="img">
                            <img v-if="MovieDetail.poster" :src="UPLOADS+MovieDetail.poster" :alt="MovieDetail.title" style="opacity: 1;
border-radius: 0px;">
                        </div>
                    </div>
                    <div class="mint_right col-8 col-lg-9 col-md-9" style="justify-content: normal;">
                        <h3 class="fn__maintitle xsfot" data-align="left">{{ MovieDetail.title }}  <span class="bgld bor5" >{{ Languages[MovieDetail.lang] }}</span> </h3>

<ShowTime :showtimes="currentShowtime" ></ShowTime>
<MovieDescription :description="MovieDetail.description" :date="MovieDetail.dt" :dir='MovieDetail.dir' :cast='MovieDetail.cast' :producer='MovieDetail.prod' :trailer="MovieDetail.trailer" :is3d="MovieDetail.td" :cert="MovieDetail.cert"></MovieDescription>   
     
                    </div> </div>
<MovieDescriptionMob :description="MovieDetail.description" :date="MovieDetail.dt" :dir='MovieDetail.dir' :cast='MovieDetail.cast' :producer='MovieDetail.prod' :trailer="MovieDetail.trailer" :is3d="MovieDetail.td" :cert="MovieDetail.cert"></MovieDescriptionMob>
                      </div>
                </div>
    <!-- ==================== Start Footer ==================== -->

<SiteFooter></SiteFooter>
</template>

<script>
import axios from 'axios';
import { URL,UPLOADS } from '@/Conf';
import SiteProgress from '../UI/SiteProgress.vue';
import SiteFooter from '../UI/SiteFooter.vue';
import SiteHeader from '../UI/SiteHeader.vue';
import DatePaginator from './DatePaginator.vue';
import MovieDescription from './MovieDescription.vue';
import MovieDescriptionMob from './MovieDescriptionMob.vue';
import ShowTime from './ShowTime.vue';
export default {
  data(){
    return{
      MovieDetail:null,
      LoadingDetail:true,
      showtimeLoader:true,
      UPLOADS,
      Languages:{"1":"Malayalam",'2':"Tamil",'3':"Hindi",'4':"English",'5':"Telugu",'6':"Kannada"},
      days:{"0":"Sun","1":"Mon","2":"Tue","3":"Wed","4":"Thu","5":"Fri","6":"Sat"},
      months:{"01":"Jan","02":"Feb","03":"Mar","04":"Apr","05":"May","06":"Jun","07":"Jul","08":"Aug","09":"Sep","10":"Oct","11":"Nov","12":"Dec"},
      showDates:[],
      showTimes:[],
      bookedSeats:{},
      currentShowtime:[],
      datePages:{},
      currentPage:0,
      firstPage:0,
      lastIndex:5,
      firstIndex:0,
      plus:0,
      lastPage:0
    }
  },
    components:{
        SiteProgress,
        SiteHeader,
        SiteFooter,
        DatePaginator,
        MovieDescription,
        ShowTime,
        MovieDescriptionMob
    },
    methods:{
      GetMovieDetail(){
        axios.get(URL + "movie/"+this.$route.params.slug).then((response) => {
        this.MovieDetail = response.data.result;
      })
      .then(()=>{
        this.LoadingDetail=false;
        this.showtimeLoader=false;
        axios.get(URL + "showtime/1/"+ this.MovieDetail.id).then((response) => {
        this.showTimes = response.data.result.showtime;
        this.getBooked();
        this.populatePage();
        this.setCurShowTime();
      })
      .then(()=>{
        
      })
      })
      },
      getBooked(){
       axios.get(URL + "seatcount/1/"+ this.MovieDetail.id).then((response) => {
        this.bookedSeats=response.data.result.booked;
        this.setCurShowTime();
      })
      },
      
      populatePage(){
        this.showDates=Object.keys(this.showTimes);
        this.datePages=[];
        this.lastPage=this.showDates.length;
        for(var i=0;i<this.lastPage;i++){
         var splited=this.showDates[i].split("-");
           var pg={"day":this.days[splited[3]]+" "+splited[2],"mth":this.months[splited[1]]}
           this.datePages.push(pg); 
        }
        

      },
      setCurShowTime(){
        //this.showTimes[this.showDates[this.currentPage]]["blocked"]="100";
        if(this.showTimes){
              this.currentShowtime=this.showTimes[this.showDates[this.currentPage]];
              for(let stime in this.currentShowtime){
              //console.log(this.currentShowtime[stime])
              this.currentShowtime[stime]["booked"]=this.bookedSeats[stime.id];
              }}
      },
      onPageChange(page) {
      this.currentPage = page;
      this.setCurShowTime();
    }
      
    },

    mounted() {
      this.GetMovieDetail();

    },
}
</script>





